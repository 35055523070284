.imageupload {
  cursor: pointer;

  &_input {
    background-color: #eaeaea;
    height: 150px;
    width: 150px;
    padding: 20px;
    border-radius: 6px;
    text-align: center;

    &_icon {
      span {
        font-size: 22px;
      }
    }

    &_text {
      font-size: 16px;
    }
  }

  &_preview {
    position: relative;

    &_image {
      height: 150px;
      width: 150px;
      border-radius: 6px;
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
      }
    }

    &_overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: #000000;
      opacity: 0.5;
      border-radius: 6px;
      display: none;
    }

    &_text {
      position: absolute;
      bottom: 10px;
      left: 0;
      width: 100%;
      font-size: 14px;
      text-align: center;
      color: #ffffff;
      display: none;
    }

    &:hover {
      .imageupload_preview_overlay {
        display: block;
      }

      .imageupload_preview_text {
        display: block;
      }
    }
  }
}