.featuredproduct {
  &_createform {
    padding: 20px;

    &_title {
      font-size: 16px;
    }
     &_form {
       padding-top: 10px;
     }
  }
}